export var salesdataForm = {
  value: 'salesdata',
  label: 'Sales Organisation Data',
  type: 'multiline',
  model: 'saleData',
  data: [],
  tabData: [{
      label: 'Sales Organisation',
      model: 'Sales_Org',
      type: 'select',
      method: 'api',
      required: true,
      source: 'salesOrgSet',
      reloadFields: ['DistributionChannel'],
      reloadParam: 'Vkorg',
      data: [],
      fieldValues: ['Vkorg', 'Vtext'],
      takeValue: 'Vkorg',
      showValues: ['Vkorg', 'Vtext'],
      massKey: "Sales_Org",
      size: 4
      
    },
    { ///parameters will be passed
      label: 'Distribution Channel',
      model: 'DistributionChannel',
      type: 'selectMulti',
      method: 'api',
      source: 'distributionChannelSet',
      reloadFields: ['Division'],
      reloadParam: 'Vtweg',
      extraParamKey: 'Vkorg',
      extraParamValue: 'Sales_Org',
      data: {},
      fieldValues: ['Vtweg', 'Vtext'],
      takeValue: 'Vtweg',
      showValues: ['Vtweg', 'Vtext'],
      massKey: "Distribution_Channel",
      size: 4,
      flagReload: true,
      required: true
    },
    { ///parameters will be passed
      label: 'Division',
      model: 'Division',
      type: 'selectMulti',
      method: 'api',
      source: 'divisionSet',
      data: {},
      fieldValues: ['Spart', 'Vtext'],
      takeValue: 'Spart',
      showValues: ['Spart', 'Vtext'],
      massKey: "Division",
      flagReload: true,
        size: 4
    },
    {
      label: 'Account Assignment Group',
      model: 'accountDetnProductGroup',
      type: 'select',
      method: 'api',
      source: 'accAssgnGrpSet',
      data: [],
      fieldValues: ['Ktgrm', 'Vtext'],
      takeValue: 'Ktgrm',
      showValues: ['Ktgrm', 'Vtext'],
      massKey: "Account_Assignment_Group",
        size: 4
    },
    {
      label: 'Item Category Group',
      model: 'ItemCategory',
      type: 'select',
      method: 'api',
      source: 'itemCategoryGrpSet',
      data: [],
      fieldValues: ['Mtpos', 'Bezei'],
      takeValue: 'Mtpos',
      showValues: ['Mtpos', 'Bezei'],
      massKey: "Item_Category_Group",
      size: 4,
      required: true
    },
    {
      label: 'Availability Check',
      model: 'availabilityCheckType',
      type: 'select',
      method: 'api',
      source: 'availCheckSet',
      data: [],
      fieldValues: ['Mtvfp', 'Bezei'],
      takeValue: 'Mtvfp',
      showValues: ['Mtvfp', 'Bezei'],
      massKey: "Availability_Check",
      size: 4,
      required: true
    },
    {
      label: 'Loading Group',
      model: 'LoadingGrp',
      type: 'select',
      method: 'api',
      source: 'loadGroupSet',
      data: [],
      fieldValues: ['Ladgr', 'Vtext'],
      takeValue: 'Ladgr',
      showValues: ['Ladgr', 'Vtext'],
      massKey: "Loading_Group",
      size: 4
    },
    {
      label: 'Transportation Group',
      model: 'TranportationGrp',
      type: 'select',
      method: 'api',
      source: 'transportGroupSet',
      data: [],
      fieldValues: ['Tragr', 'Vtext'],
      takeValue: 'Tragr',
      showValues: ['Tragr', 'Vtext'],
      massKey: "Transportation_Group",
      size: 4
    }
  ]
};
