export var basicForm = {
    value: 'basic',
    label: 'Basic Data',
    type: 'standard',
    tabData: [{
        label: 'Analysis Period From',
        type: 'date',
        required: true,
        model: 'validFrom',
        size: 4,
        maxLength: 8,
        massKey: "Analysis_Period_from",
      },
      {
        label: 'Analysis Period To',
        type: 'date',
        required: true,
        model: 'validTo',
        size: 4,
        maxLength: 8,
        massKey: "Analysis_period_to",
      },
      {
        label: 'Name',
        type: 'text',
        required: true,
        model: 'name',
        size: 4,
        maxLength: 10,
        massKey: "Name",
      },
      {
        label: 'Long Text',
        type: 'text',
        required: true,
        model: 'longText',
        size: 4,
        maxLength: 20,
        massKey: "Long_text",
      },
      {
        label: 'User Responsible',
        model: 'userResponsible',
        type: 'select',
        method: 'api',
        required: true,
        source: 'userResponsible',
        data: [],
        fieldValues: ['Bname', ],
        takeValue: 'Bname',
        showValues: ['Bname',],
        size: 4,
        maxLength: 12,
        massKey: "User_Responsible",
      },
      {
        label: 'Person Responsible',
        type: 'text',
        required: true,
        model: 'personResponsible',
        size: 4,
        maxLength: 12,
        massKey: "Person_Responsible",
      },
      {
        label: 'Department',
        type: 'text',
        required: true,
        model: 'department',
        size: 4,
        maxLength: 12,
        massKey: "Department",
      },
      {
        label: 'Profit Center Group',
        model: 'group',
        type: 'select',
        method: 'api',
        required: true,
        source: 'group',
        data: [],
        fieldValues: ['Groupname'],
        takeValue: 'Groupname',
        showValues: ['Groupname'],
        size: 4,
        maxLength: 12,
        massKey: "Profit_Center_Group",
        flagReload: true,
      },
      {
        label: 'Segment',
        model: 'segment',
        type: 'select',
        method: 'api',
        source: 'segmentPrSet',
        data: [],
        fieldValues: ['Segment', 'Name'],
        takeValue: 'Segment',
        showValues: ['Segment', 'Name'],
        size: 4,
        maxLength: 10,
        massKey: "Segment",
      }
      
    ]
  };
  