export var Create_Bank_Interest = {
  value: 'Bank',
  label: 'Create/Bank/Interest',
  type: 'standard',
  tabData: [
    {
      // label: 'Add Status Group',
      label: ' Field Status Group ',
      type: 'select',
      model: 'addStatusGroup',
      method: 'api',
      source: 'fieldStatusGrp',
      required: true,
      data: [],
      fieldValues: ['Fstag', 'Fsttx'],
      takeValue: 'Fstag',
      showValues: ['Fstag', 'Fsttx'],
      massKey: "FIELD_STATUS_GROUP",
      flagReload: true,
      size: 4,
      maxLength: 3
    },
    {
      label: 'Post Automatically',
      type: 'checkbox',
      model: 'postAutomatically',
      massKey: "POST_AUTOMATICALLY",
      size: 4,
      maxLength: 3
    },
  ]}