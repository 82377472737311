export var initialForm = {
    value: 'initial',
    label: 'Initial Screen',
    type: 'standard',
    tabData: [
      {
        label: 'Controlling Area',
        type: 'select',
        model: 'controllingArea',
        method: 'api',
        source: 'controllingArea',
        reloadFields: ['hierarchyArea'],
        reloadParam: 'CoArea',
        screen: ['basic'],       ////in which form we have to call the api   
        required: true,
        data: [],
        fieldValues: ['Kokrs', 'Bezei'],
        takeValue: 'Kokrs',
        showValues: ['Kokrs', 'Bezei'],
        size: 4,
        massKey: "Controlling_Area",
        
      },
      {
        label: 'Cost Center',
        type: 'text',
        required: true,
        model: 'costCenter',
        size: 4,
        massKey: "Cost_Center",
      },
      {
        label: 'Valid Period From',
        type: 'date',
        required: true,
        model: 'validFrom',
        size: 4,
        massKey: "Valid_Period_from",
      },
      {
        label: 'Valid Period To',
        type: 'date',
        required: true,
        model: 'validTo',
        size: 4,
        massKey: "Valid_Period_to",
      }
    ]
  };
  