import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-show-bom-mass',
  templateUrl: './show-bom-mass.page.html',
  styleUrls: ['./show-bom-mass.page.scss'],
})
export class ShowBomMassPage implements OnInit {
  @Input() request: any;
  displayArr:any=[]
  columnData:any=[]
   constructor(public modalController: ModalController,
 
   ) { }
 
   ngOnInit() {
     console.log("request data is ",this.request)
     this.displayArr=this.request.massData;
     console.log("request data is ",this.displayArr)
     this.columnData = Object.keys(this.displayArr[0]);
   }
   close() {
     this.modalController.dismiss();
   }

}
