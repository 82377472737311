import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hana-db',
  templateUrl: './hana-db.page.html',
  styleUrls: ['./hana-db.page.scss'],
})

export class HanaDbPage implements OnInit {

  userHost: any;
  userName: any;
  userPassword: any;
  userDatabase: any;
  databaseKeys: any = {};
  userId: any;
  filePath: any;
  database: any;
  userPort: any;
  tableName: any;
  dataName: any;
  error:null;

  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';

  constructor(public modalController: ModalController, public commonService: CommonService,
    private http: HttpClient, private router: Router,
    private navController: NavController) { }

  ngOnInit() {
    this.dataName = this.commonService.getdataName();
  }

  close() {
    this.modalController.dismiss();
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  createConnection() {
    let connectionString = `hana://${this.userName}:${encodeURIComponent(this.userPassword)}@${this.userHost}:${this.userPort}`
    let tableName=this.tableName;
    let filePath = this.filePath;

    let pattern = /^\w+$/;
    let result = pattern.test(tableName);

    if(result){ 
      this.commonService.presentLoading("Sending data to HANA DB");
      this.commonService.sendDataToHana(
        connectionString,
        tableName,
        filePath
      ).then((resp: any) => {
  
  
        this.commonService.loadingDismiss();
        this.commonService.showToast("success", resp.msg)
        this.modalController.dismiss(resp)
  
      },
      error=>{
        if (error.status == 200) {
          this.error = error.message;
          this.commonService.dmacshowToast("error", this.error);            
          console.log(error)
        }
        if (error.status == 400) {
          if(error.error.msg){
            this.error = error.error.msg;
            this.commonService.dmacshowToast("error", this.error);          
            console.log(error)
          }
          else{
            this.commonService.dmacshowToast("error", "Something went wrong!");   
          }
          }        
        if (error.status == 502) {
          this.error = error.message;
          this.commonService.dmacshowToast("error", this.error);         
          console.log(error)
        }
        // this.error=error.message;
        // this.commonService.showToast("error", this.error);
        // console.log(error)
      })
      return false;
    } else {
      this.commonService.dmacshowToast("error", "Table name not valid, cannot contain special characters!"); 
    }

  }

}