// export var initialForm = {
//     value: 'initial',
//     label: 'Initial Screen',
//     type: 'standard',
//     tabData: [{
//         label: 'Material',
//         type: 'text',
//         model: 'material',
//         required: true,
//         massKey: "MATERIAL"
//         // disabled: true
//       },
//       {
//         label: 'Plant',
//         type: 'select',
//         method: 'api',
//         source: 'plant',
//         model: 'plant',
//         data: [],
//         required: true,
//         fieldValues: ['Werks', 'Name1'],
//         takeValue: 'Werks',
//         showValues: ['Werks', 'Name1'],
//         size: 4,
//         massKey: "PLANT"
//       },
//       {
//         label: 'BOM Usage',
//         type: 'select',
//         method: 'api',
//         source: 'bomUsageSet',
//         model: 'bomUsage',
//         data: [],
//         required: true,
//         fieldValues: ['Stlan', 'Antxt'],
//         takeValue: 'Stlan',
//         showValues: ['Stlan', 'Antxt'],
//         size: 4,
//         massKey: "BOM_USAGE"
//       }
//       ],
//       validations: [{
//         fields: ['material', 'plant'],
//         keys: ['Matnr', 'Werks'],
//         source: 'materialInPlantValidation',
//         model: 'BOM',
//         onSelect: ['material', 'plant'],
//         size: 4
//       },
//       {
//         fields: ['material', 'plant', 'bomUsage'],
//         keys: ['Matnr', 'Werks', 'Stlan'],
//         source: 'altBomSet',
//         model: 'BOM',
//         onSelect: ['bomUsage'],
//         size: 4
//       }],
//   };


export var initialForm = {
  value: 'initial',
  label: 'Header Data',
  type: 'standard',
  tabData: [{
    label: 'Material',
    type: 'text',
    model: 'material',
    required: true,
    massKey: "MATERIAL",
    size:4
  },
  {
    label: 'Plant',
    model: 'plant',
    type: 'select',
    method: 'api',
    required: true,
    source: 'plantBOM',
    // param: "type",
    // paramValue: "infoRecord",
    data: [],
    fieldValues: ['plantBOM', 'name1'],
    takeValue: 'plantBOM',
    showValues: ['plantBOM', 'name1'],
    massKey: "PLANT",
    size: 4
  }, 
  // {
  //   label: 'BOM Usage',
  //   type: 'text',
  //   model: 'bomUsage',
  //   required: true,
  //   size: 4,
  //   massKey: "BOM_USAGE"
  // },
  {
    label: 'BOM Usage',
    model: 'bomUsage',
    type: 'select',
    method: 'api',
    required: true,
    source: 'bomUsage',
    default: '1',
    // param: "type",
    // paramValue: "infoRecord",
    data: [],
    fieldValues: ['usage', 'description'],
    takeValue: 'usage',
    showValues: ['usage', 'description'],
    size: 4
  }, 
  ]
};

