import { Component, OnInit ,Input} from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-showmaterial-mass-info',
  templateUrl: './showmaterial-mass-info.page.html',
  styleUrls: ['./showmaterial-mass-info.page.scss'],
})
export class ShowmaterialMassInfoPage implements OnInit {
  @Input() request: any;
 displayArr:any=[]
 columnData:any=[]
  constructor(public modalController: ModalController,

  ) { }

  ngOnInit() {
    console.log("request data is ",this.request)
    this.displayArr=this.request.massData;
    console.log("request data is ",this.displayArr)
    this.columnData = Object.keys(this.displayArr[0]);
  }
  close() {
    this.modalController.dismiss();
  }
}
