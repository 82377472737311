export var initialForm = {
    value: 'initial',
    label: 'Initial Screen',
    type: 'standard',
    tabData: [{
        label: 'Material',
        type: 'text',
        model: 'material',
        required: true,
        size: 4,
        massKey: "Material_Number",
        
      },
      {
        label: 'Plant',
        type: 'select',
        model: 'plant',
        method: 'api',
        source: 'plantHelp',
        required: true,
        data: [],
        fieldValues: ['Werks', 'Name1'],
        takeValue: 'Werks',
        showValues: ['Werks', 'Name1'],
        size: 4,
        visible: true,
        massKey: "Plant",
      },
      {
        label: 'Key date',
        type: 'date',
        model: 'keyDate',
        required: true,
        size: 4
      }
    ],
    // validations: [{
    //   fields: ['material', 'plant'],
    //   keys: ['Matnr', 'Werks'],
    //   source: 'materialInPlantValidation',
    //   model: 'Routing',
    //   onSelect: ['material', 'plant'],
    //   size: 4
    // }]
  };
  