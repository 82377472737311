export var basicForm = {
    value: 'basic',
    label: 'Basic Data',
    type: 'standard',
    tabData: [
      {
        label: 'Name',
        type: 'text',
        required: true,
        model: 'name',
        size: 4,
        massKey: "Name",
      },
      {
        label: 'Description',
        type: 'text',
        required: true,
        model: 'description',
        size: 4,
        massKey: "Description",
      },
      {
        label: 'User Responsible',
        type: 'select',
        method: 'api',
        model: 'userResponsible',
        source: 'userResponsible',
        required: true,
        data: [],
        fieldValues: ['Bname'],
        takeValue: 'Bname',
        showValues: ['Bname'],
        size: 4,
        massKey: "User_Responsible",
      },
      {
        label: 'Person Responsible',
        type: 'text',
        required: true,
        model: 'personResponsible',
        size: 4,
        massKey: "Person_Responsible",
      },
      {
        label: 'Department',
        type: 'text',
        required: true,
        model: 'department',
        size: 4,
        massKey: "Department",
      },
      {
        label: 'Cost Center Category',
        // model: 'costCenterCat',
        model: 'ccCategory',
        type: 'select',
        method: 'api',
        required: true,
        source: 'ccCategory',
        data: [],
        fieldValues: ['Kosar', 'Ktext'],
        takeValue: 'Kosar',
        showValues: ['Kosar', 'Ktext'] ,
        size: 4,
        massKey: "Cost_Center_Category",
      },
      {
        label: 'Hierarchy Area',////have to take payload from initial screeen
        model: 'hierarchyArea',
        type: 'select',
        method: 'api',
        required: true,
        source: 'hierarchyArea',
        data: [],
        fieldValues: ['Groupname'],
        takeValue: 'Groupname',
        showValues: ['Groupname'] ,
        size: 4,
        massKey: "Hierarchy_Area",
        flagReload: true,
      },
      {
        label: 'Business Area',////have to take payload from initial screeen
        model: 'businessArea',
        type: 'select',
        method: 'api',
        required: true,
        source: 'businessAreaSet',
        data: [],
        fieldValues: ['Gsber','Gtext'],
        takeValue: 'Gsber',
        showValues: ['Gsber','Gtext'] ,
        size: 4,
        massKey: "Business_Area",
      },
      {
        label: 'Functional Area',////have to take payload from initial screeen
        model: 'functionalArea',
        type: 'select',
        method: 'api',
        required: false,
        source: 'functionalAreaSet',
        data: [],
        fieldValues: ['Fkber','Fkbtx'],
        takeValue: 'Fkber',
        showValues: ['Fkber','Fkbtx'] ,
        size: 4,
        massKey: "Functional_Area",
      },
      {
        label: 'Currency',////have to take payload from initial screeen
        model: 'currency',
        type: 'select',
        method: 'api',
        required: true,
        source: 'currencySet',
        data: [],
        fieldValues: ['Waers','Ltext','Ktext'],
        takeValue: 'Waers',
        showValues: ['Waers','Ktext'] ,
        size: 4,
        massKey: "Currency",
      },
      {
        label: 'Profit Center',////have to take payload from initial screeen
        model: 'profitCenter',
        type: 'select',
        method: 'api',
        required: true,
        source: 'profitCenterSet',
        data: [],
        fieldValues: ['Kokrs','Prctr'],
        takeValue: 'Prctr',
        showValues: ['Kokrs','Prctr'] ,
        size: 4,
        massKey: "Profit_Center",
      }
    ]
  };
  