import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilderComponent } from './form-builder.component';
// import { NgxAutocompleteModule } from 'ngx-angular-autocomplete'


@NgModule({
  declarations: [ FormBuilderComponent ],
  imports: [ 
    CommonModule, 
    // NgxAutocompleteModule
  ],
  exports : [ FormBuilderComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class FormBuilderModule { }
