export var basicForm = {
    value: 'basic',
    label: 'Attachments',
    type: 'standard',
    tabData: [
        {
            label: 'PIM Image-1',
            type: 'file',
            model: 'imageattachment1',
            size: 4,
            // accept:"pdf"
          },
          {
            label: 'PIM Image-2',
            type: 'file',
            model: 'imageattachment2',
            size: 4,
            // accept:"pdf"
          },
          {
            label: 'PIM Image-3',
            type: 'file',
            model: 'imageattachment3',
            size: 4,
            // accept:"pdf"
          }

    ]
  };
  