import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-db-column-splitter-modal',
  templateUrl: './db-column-splitter-modal.page.html',
  styleUrls: ['./db-column-splitter-modal.page.scss'],
})

export class DbColumnSplitterModalPage implements OnInit {
  @Input() filePath;
  splitBy: any;
  columnToSplit: any;
  rules: any;
  dataName: any = [];
  val: any = [];
  dict: any = [];
  form: any;
  dicts: any = [];
  error: null;

  constructor(public fb: FormBuilder,
    private modalCtrl: ModalController,
    public commonService: CommonService,
    private http: HttpClient,
    private navController: NavController,
    public router: Router) { }

  ngOnInit() {
    this.dataName = this.commonService.getdataName();
    this.val = this.commonService.getselectedPKeys();
    // console.log(typeof (this.dataName))
    // console.log("sonaliiiiii", this.dataName[0])
  }
  close() {
    this.modalCtrl.dismiss();
  }

  createRequest() {
    if (this.dict == null) {
      this.commonService.showToast('error', "Field required");
    } else if (this.columnToSplit == null) {
      this.commonService.showToast('error', "Field required");
    } else if (this.splitBy == null) {
      this.commonService.showToast('error', "Field required");
    } else {
      let formData = {
        splitBy: this.splitBy,
        dict: this.dicts,
        columnToSplit: this.columnToSplit,
        operation: "Column Splitter",
        dataName: this.filePath
      }
      // console.log(formData)
      this.commonService.applyDBRules(formData).then((res: any) => {
        this.commonService.showToast('success', "Table created.");
        this.val = res;
        this.modalCtrl.dismiss(res)
      },
        error => {
          if (error.status == 200) {
            this.error = error.message;
            this.commonService.dmacshowToast("error", this.error);            
            console.log(error)
          }
          if (error.status == 400) {
            if(error.error.msg){
              this.error = error.error.msg;
              this.commonService.dmacshowToast("error", this.error);          
              console.log(error)
            }
            else{
              this.commonService.dmacshowToast("error", "Something went wrong!");   
            }
            }        
          if (error.status == 502) {
            this.error = error.message;
            this.commonService.dmacshowToast("error", this.error);         
            console.log(error)
          }
          // this.error = error.message;
          // this.commonService.showToast("error", this.error);
          // console.log(error)
        })
    }
  }

  addDict() {
    // console.log(this.dict)
    if (this.dicts.indexOf(this.dict)) {
      this.dicts.push(this.dict);
      this.dict = "";
    }
  }

  removeCode(code) {
    this.dicts.splice(this.dicts.indexOf(code), 1);
  }

}