export var bom = {
  value: 'bom',
  label: 'Component Data',
  type: 'multiline',
  model: 'bom',
  data: [],
  tabData: [
    {
    label: 'Component Header',
    type: 'text',
    model: 'componentHeader',
    // required: true,
    size: 4
  },
  {
    label: 'Item Category',
    model: 'itemCategory',
    type: 'select',
    method: 'api',
    required: true,
    source: 'bomItemCategory',
    default: 'L',
    // param: "type",
    // paramValue: "infoRecord",
    data: [],
    fieldValues: ['itemCategory', 'description'],
    takeValue: 'itemCategory',
    showValues: ['itemCategory', 'description'],
    size: 4
  },
  {
    label: 'Component',
    type: 'text',
    model: 'component',
    required: true,
    size: 4
  },
  // {
  //   label: 'Quantity',
  //   type: 'text',
  //   model: 'quantity',
  //   required: true,
  //   size: 4
  // },
  // {
  //   label: 'Unit of Measure',
  //   type: 'text',
  //   model: 'baseUOM',
  //   required: true,
  //   size:4
  // },
  {
    label: 'Unit of Measure',
    model: 'baseUOM',
    type: 'select',
    method: 'api',
    required: true,
    source: 'bomUOM',
    // param: "type",
    // paramValue: "infoRecord",
    data: [],
    fieldValues: ['IntMeasUnit', 'measurementUnitText'],
    takeValue: 'IntMeasUnit',
    showValues: ['IntMeasUnit', 'measurementUnitText'],
    size: 4
  },
  {
    label: 'Valid From',
    type: 'date',
    model: 'validFrom',
    mindate: 'current',
    massKey: "valid_from",
    required: true,
    size: 4
  },
  {
    label: 'Valid To',
    type: 'date',
    model: 'validTo',
    mindate: 'current',
    massKey: "valid_To",
    required: true,
    size: 4
  },
  {
    label: 'Consumption',
    type: 'text',
    model: 'consumption',
    required: true,
    size: 4
  },
  {
    label: 'Material Group',
    type: 'text',
    model: 'materialGroup',
    required: true,
    size: 4
  },
  {
    label: 'Material Type',
    type: 'text',
    model: 'materialType',
    required: true,
    size: 4
  },
  {
    label: 'Material Grid Number',
    type: 'text',
    model: 'materialGridNo',
    required: true,
    size: 4
  },
  {
    label: 'Default Grid Value',
    type: 'text',
    model: 'defaultGridValue',
    required: false,
    size: 4
  },
  // {
  //   label: 'Wastage',
  //   type: 'text',
  //   model: 'wastage',
  //   // required: true,
  //   size: 4
  // }
  ],
  // tabDataNested:  {
  //   type: 'multiline',
  //   model: 'sizeWise',
  //   text: 'Add Size Wise Mapping',
  //   data: {},
  //   visible: true,
  //   tabData: [
  //   {
  //     label: 'Header Size',
  //     type: 'text',
  //     model: 'headerSize',
  //     // required: false,
  //     size: 4
  //   },
  //   {
  //     label: 'Item Size',
  //     type: 'text',
  //     model: 'itemSize',
  //     // required: false,
  //     size: 4
  //   },
  //   {
  //     label: 'Consumption',
  //     type: 'text',
  //     model: 'consumption',
  //     // required: false,
  //     size: 4
  //   }
  // ]
  // },
};

