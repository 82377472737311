export var ControlData = {
  value: 'control',
  label: 'Control Data',
  type: 'standard',
  tabData: [
    {
      label: 'Account Currency',
      type: 'select',
      model: 'accountCurrency',
      method: 'api',
      source: 'accountCurrency',
      required: false,
      data: [],
      fieldValues: ['Waers', 'Ltext'],
      takeValue: 'Waers',
      showValues: ['Waers', 'Ltext'],
      massKey: "ACCOUNT_CURRENCY",
      size: 4
    },
    {
      label: 'Tolerance Group',
      type: 'select',
      model: 'toleranceGroup',
      method: 'api',
      source: 'toleranceGroup',
      required: false,
      data: [],
      fieldValues: ['Bukrs', 'Togru'],
      takeValue: 'Togru',
      showValues: ['Bukrs', 'Togru'],
      // massKey: "TOLERANCE_GROUP",
      size: 4
    },
    {
      label: 'Sort Key',
      type: 'select',
      model: 'sortKey',
      method: 'api',
      source: 'sortKey',
      required: false,
      data: [],
      fieldValues: ['Zuawa', 'Togru'],
      takeValue: 'Zuawa',
      showValues: ['Zuawa', 'Togru'],
      massKey: "SORT_KEY",
      size: 4
    },
    {
      label: 'Tax Category',
      type: 'select',
      model: 'taxCategory',
      method: 'api',
      source: 'taxCategoryGLSet',
      required: false,
      data: [],
      fieldValues: ['Mwskz', 'Text1'],
      takeValue: 'Mwskz',
      showValues: ['Mwskz', 'Text1'],
      massKey: "TAX_CATEGORY",
      size: 4,
      maxLength: 10
    },
    {
      label: 'Reconciliation Account Type',
      type: 'select',
      model: 'reconciliationAccountType',
      method: 'api',
      source: 'glReconciliationAccountTypeSet',
      required: false,
      data: [],
      fieldValues: ['DomvalueL', 'Ddtext'],
      takeValue: 'DomvalueL',
      showValues: ['DomvalueL', 'Ddtext'],
      massKey: "RECON_ACCOUNT_FOR_ACCOUNT_TYPE",
      size: 4,
      maxLength: 35
    }
  ]
  }