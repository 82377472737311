
export var HTS = {
  value: 'HTS',
  label: 'HTS',
  type: 'multiline',
  model: 'HTS',
  data: [],
  tabDataStandard: [
    {
      label: 'Search by Description',
      type: 'checkbox',
      model: 'searchByDescription',
      hideViewField: true,
      size: 4,
      dependentWith: 'searchByNumber'
    },
    {
      label: 'Search by Number',
      type: 'checkbox',
      model: 'searchByNumber',
      hideViewField: true,
      size: 4,
      dependentWith: 'searchByDescription'
    },
    {
      label: 'Select Material',
      type: 'search',
      visibleSegment: 'questionaire',
      method: 'api',
      source: 'productMaster',
      model: 'productNo',
      data: [],
      required: true,
      fieldValues: ['Matnr', 'Maktg'],
      takeValue: 'Matnr',
      showValues: ['Matnr', 'Maktg'],
      size: 8,
      massKey: "Material",
    }
  ],
  tabData: [
    {
      label: 'Country of Origin',
      type: 'select',
      method: 'api',
      model: 'country',
      source: 'country',
      data: [],
      fieldValues: ['Land1', 'Landx'],
      takeValue: 'Land1',
      showValues: ['Land1', 'Landx'],
      massKey: "Material",
    },
    {
      label: 'HTS search keyword',
      type: 'text',
      // visibleTab: ['corruption'],
      // visibleTabValue: null,
      // visibleSegment: 'questionaire',
      // method: 'api',
      source: 'productMaster',
      model: 'hts_search',
      reloadFields: ['htsNo'],
      reloadParam: 'Maktg',
      data: [],
      // required: true,
      fieldValues: ['Maktg'],
      takeValue: 'Matnr',
      showValues: ['Maktg'],
      size: 5,
      massKey: "Material",
    },
    {
      label: 'HTS Code',
      type: 'selectMulti',
      method: 'api',
      source: 'htsNo',
      model: 'htsNo',
      data: [],
      fieldValues: ['htsno', 'description'],
      takeValue: 'htsno',
      showValues: ['htsno', 'description'],
      massKey: "Material",
    },
    // {
    //   label: 'HTS Code',
    //   model: 'htsNo',
    //   type: 'selectMulti',
    //   method: 'api',
    //   source: 'htsNo',
    //   data: {},
    //   required: true,
    //   fieldValues: ['htsno', 'description'],
    //   takeValue: 'htsno',
    //   showValues: ['htsno', 'description'],
    //   flagReload: true,
    //   size: 4
    // },
  ]
};
