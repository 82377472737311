export var initialForm = {
  value: 'initial',
  label: 'Attributes',
  type: 'standard',
  tabData: [
    {
      label: 'Article Number/ID',
      type: 'text',
      model: 'articleNumber',
      size: 4,
      maxLength: 20
    },
    {
      label: 'Article Name/Description',
      type: 'text',
      model: 'articleDescription',
      size: 4,
      maxLength: 100
    },
    {
      // label: 'Category/Type',
      label: 'Category Types',
      type: 'select',
      model: 'articleCategory',
      method: 'local',
      source: 'article_Category',
      // visibilityValue: 'Shoes',
      // visibleFields: 'articleSizes',
      // hideValue:'Shoes',
      // hideField: 'articleSize',
      reloadFields: ['articleSize'],
      reloadParam: 'key',
      data: {},
      fieldValues: ['value'],
      takeValue: 'value',
      showValues: ['value'],
      size: 4,
      maxLength: 50
    },
    {
      label: 'Season/Collection',
      type: 'select',
      model: 'articleSeason',
      method: 'local',
      source: 'article_Season',
      data: {},
      fieldValues: ['value'],
      takeValue: 'value',
      showValues: ['value'],
      size: 4,
      maxLength: 50
    },
    {
      label: 'Brand',
      type: 'text',
      model: 'articleBrand',
      size: 4,
      maxLength: 50
    },
    {
      // label: 'Color',
      label: 'Flavour',
      type: 'multiselect',
      model: 'articleColor',
      method: 'local',
      // param: 'type',
      // paramValue: 'articleColor',
      source: 'article_Color',
      data: [],
      fieldValues: ['value'],
      takeValue: 'value',
      showValues: ['value'],
      size: 4,
      maxLength: 30
    },
    {
      // label: 'Size Range',
      label: 'Weight Range',
      type: 'multiselect',
      model: 'articleSize',
      method: 'api',
      source: 'article_Size',
      data: [],
      fieldValues: ['selectedValue'],
      takeValue: 'selectedValue',
      showValues: ['selectedValue'],
      size: 4,
      maxLength: 20
    },
    {
      label: 'Material/Fabric',
      type: 'text',
      model: 'articleMaterial',
      size: 4,
      maxLength: 50
    },
    {
      label: 'Supplier/Vendor',
      type: 'text',
      model: 'articleSupplier',
      size: 4,
      maxLength: 50
    },
    {
      label: 'Cost/Price',
      type: 'number',
      model: 'articleCost',
      size: 4,
      maxLength: 10
    },
    {
      label: 'Currency',
      model: 'pim_currency',
      type: 'select',
      method: 'api',
      source: 'pimcurrency',
      data: [],
      // required: true,
      fieldValues: ['Ktext', 'Ltext', 'Waers'],
      takeValue: 'Waers',
      showValues: ['Waers', 'Ktext'],
      size: 4
    },
    {
      label: 'Design Details/Specifications',
      type: 'text',
      model: 'articleDesign',
      size: 4,
      maxLength: 255
    },
    {
      label: 'SKU (Stock Keeping Unit)',
      type: 'text',
      model: 'articleSku',
      size: 4,
      maxLength: 20
    },
    {
      label: 'Target Market',
      type: 'text',
      model: 'articleTarget',
      size: 4,
      maxLength: 50
    },
    {
      label: 'Launch Date',
      type: 'date',
      model: 'articleLaunch',
      size: 4,
      maxLength: 10
    },
    {
      label: 'Production Method',
      type: 'text',
      model: 'articleProduction',
      size: 4,
      maxLength: 50
    },
    {
      label: 'Lead Time',
      type: 'number',
      model: 'articleLead',
      size: 4,
      maxLength: 5
    },
    {
      label: 'Compliance/Certifications',
      type: 'text',
      model: 'articleCompliance',
      size: 4,
      maxLength: 100
    },
    {
      label: 'Barcode',
      type: 'text',
      model: 'articleBarcode',
      size: 4,
      maxLength: 20
    },
    {
      label: 'Wash/Care Instructions',
      type: 'text',
      model: 'articleWash',
      size: 4,
      maxLength: 255
    },
    {
      label: 'Weight',
      type: 'number',
      model: 'articleWeight',
      size: 4,
      maxLength: 10
    },
    {
      label: 'Weight Unit',
      type: 'select',
      method: 'local',
      model: 'articleweightUnit',
      source: 'article_weightUnit',
      data: [],
      fieldValues: ['value', 'viewValue'],
      takeValue: 'value',
      showValues: ['value', 'viewValue'],
      size: 4,
    },
    {
      label: 'Packaging Details',
      type: 'text',
      model: 'articlePackaging',
      size: 4,
      maxLength: 255
    },
    {
      label: 'Inventory Levels',
      type: 'text',
      model: 'articleInventory',
      size: 4,
      maxLength: 10
    },
    {
      label: 'Retail Price',
      type: 'number',
      model: 'articleRetail',
      size: 4,
      maxLength: 10
    },
    {
      label: 'Currency',
      model: 'pim_currency_2',
      type: 'select',
      method: 'api',
      source: 'pimcurrency',
      data: [],
      // required: true,
      fieldValues: ['Ktext', 'Ltext', 'Waers'],
      takeValue: 'Waers',
      showValues: ['Waers', 'Ktext'],
      size: 4
    },
    {
      label: 'Discount Information',
      type: 'text',
      model: 'articleDiscount',
      size: 4,
      maxLength: 100
    },
    {
      label: 'Variant Article',
      type: 'select',
      model: 'variantName',
      fetchVariant: 'article',
      data: [],
      fieldValues: ['_id', 'variantName'],
      takeValue: '_id',
      showValues: ['variantName'],
      size: 4,
      required: true
    },
]
};