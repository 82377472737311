import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-db-string-resizer-modal',
  templateUrl: './db-string-resizer-modal.page.html',
  styleUrls: ['./db-string-resizer-modal.page.scss'],
})
export class DbStringResizerModalPage implements OnInit {

  @Input() filePath;
  columnToSplit: any;
  dataName: any = [];
  val: any = [];
  limit: any;
  error: null;

  constructor(private modalCtrl: ModalController,
    public commonService: CommonService,
    private http: HttpClient,
    private navController: NavController) { }

  ngOnInit() {
    this.dataName = this.commonService.getdataName();
    this.val = this.commonService.getselectedPKeys();
    // console.log(typeof this.dataName);
    // console.log('sonaliiiiii', this.dataName[0]);
  }
  close() {
    this.modalCtrl.dismiss();
  }

  createRequest() {
    if (this.columnToSplit == null) {
      this.commonService.showToast('error', 'Field required');
    } else if (this.limit == null) {
      this.commonService.showToast('error', 'Field required');
    } else {
      let formData = {
        operation: 'String Resizer',
        dataName: this.filePath,
        values: this.columnToSplit,
        limit: this.limit.toString(),
      };
      // console.log(formData);
      this.commonService.applyDBRules(formData).then((res: any) => {
        this.val = res;
        this.commonService.showToast('success', 'Table created.');
        this.modalCtrl.dismiss(res);
      },
        error => {
          if (error.status == 200) {
            this.error = error.message;
            this.commonService.dmacshowToast("error", this.error);            
            console.log(error)
          }
          if (error.status == 400) {
            if(error.error.msg){
              this.error = error.error.msg;
              this.commonService.dmacshowToast("error", this.error);          
              console.log(error)
            }
            else{
              this.commonService.dmacshowToast("error", "Something went wrong!");   
            }
            }        
          if (error.status == 502) {
            this.error = error.message;
            this.commonService.dmacshowToast("error", this.error);         
            console.log(error)
          }
          // this.error = error.message;
          // this.commonService.showToast("error", this.error);
          // console.log(error)
        })
    }
  }
  
}
