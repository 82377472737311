export var subHeaderForm = {
    value: 'subHeader',
    label: 'Sub Header',
    type: 'standard',
    tabData: [
      {
        label: 'Sold To Party',
        // model: 'soldToParty',///ngModel
        model: 'PartnNumb',
        type: 'select',
        method: 'api',
        required: true,
        source: 'customerNumber',///end point of api
        data: [],
        fieldValues: ['Kunnr', 'Name1'],////response of api 
        takeValue: 'Kunnr',///value which is to be passed
        showValues: ['Kunnr', 'Name1'], ///values which is to be show on screen
        size: 4
      },
      {
        label: 'Ship To Party',
        // model: 'shipToParty',
        model: 'PartnNumb',
        type: 'select',
        method: 'api',
        required: true,
        source: 'customerNumber',
        data: [],
        fieldValues: ['Kunnr', 'Name1'],////response of api 
        takeValue: 'Kunnr',///value which is to be passed
        showValues: ['Kunnr', 'Name1'], ///values which is to be show on screen
        size: 4
      },
      {
        label: 'Order By Customer',
        type: 'text',
        // required: true,
        model: 'purchaseOrder',
        size: 4
      },
      {
        label: 'Sales Order Date',
        type: 'date',
        // required: true,
        model: 'sales_Order_Date',
        size: 4
      },
      {
        label: 'Currency',
        model: 'currency',
        type: 'select',
        method: 'api',
        required: true,
        source: 'currencySet',
        data: [],
        fieldValues: ['Waers', 'Ktext'],////response of api 
        takeValue: 'Waers',///value which is to be passed
        showValues: ['Waers', 'Ktext'], ///values which is to be show on screen,
        size: 4
      },
      {
        label: 'Pricing Date',
        type: 'date',
        required: true,
        model: 'pricingDate',
        size: 4
      },
      {
        label: 'Price List Type',
        model: 'priceListType',
        type: 'select',
        method: 'api',
        // required: true,
        source: 'priceList',
        data: [],
        fieldValues: ['Pltyp', 'Ptext'],////response of api 
        takeValue: 'Pltyp',///value which is to be passed
        showValues: ['Pltyp', 'Ptext'], ///values which is to be show on screen,
        size: 4
      },
      {
        label: 'Incoterms',
        model: 'incotermClassification',
        type: 'select',
        method: 'api',
        required: true,
        source: 'incoTerms',
        data: [],
        fieldValues: ['Inco1', 'Bezei'],////response of api 
        takeValue: 'Inco1',///value which is to be passed
        showValues: ['Inco1', 'Bezei'], ///values which is to be show on screen,
        size: 4
      },
      {
        label: 'Customer payment Term',
        model: 'custPaymentTerm',
        type: 'select',
        method: 'api',
        // required: true,
        source: 'paymentTerms',
        data: [],
        fieldValues: ['Zterm', 'Vtext'],////response of api 
        takeValue: 'Zterm',///value which is to be passed
        showValues: ['Zterm', 'Vtext'], ///values which is to be show on screen,
        size: 4
      },
      {
        label: 'Req Delivery Date',
        type: 'date',
        required: true,
        model: 'reqDeliveryDate',
        size: 4
      },
    ]
  };
  