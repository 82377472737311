import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.page.html',
  styleUrls: ['./view-details.page.scss'],
})
export class ViewDetailsPage implements OnInit {

  constructor(public modalController:ModalController) { }
  details:any=[
    { Domain: "General Data", Table_Structure: "01 Busines Partner",Value: "SPU00001", Mandatory: " Yes",Description: "Legal Name 1 of Organization"},
    { Domain: "General Data", Table_Structure: "07 Address",Value: "Moyse Pl", Mandatory: " Yes",Description: "Street"},
    { Domain: "General Data", Table_Structure: "07 Address",Value: "08820", Mandatory: " Yes",Description: "City Postal Code"},
    { Domain: "General Data", Table_Structure: "07 Address",Value: "Edision", Mandatory: " Yes",Description: "City"},
    { Domain: "General Data", Table_Structure: "07 Address",Value: "US", Mandatory: " Yes",Description: "Country Key"},
    { Domain: "General Data", Table_Structure: "07 Address",Value: "NJ", Mandatory: "No",Description: "Region"},
    { Domain: "General Data", Table_Structure: "07 Address",Value: "EN", Mandatory: " Yes",Description: "Bp Correspondence Language "},
    { Domain: "General Data", Table_Structure: "08 Email Address",Value: "a@gmail.com", Mandatory: " Yes",Description: "Email Address"},
    { Domain: "General Data Tax", Table_Structure: "04 Tax Numbeer",Value: "1", Mandatory: " Yes",Description: "TaxNumber1"},
    { Domain: "General Data Tax", Table_Structure: "04 Tax Numbeer",Value: "", Mandatory: " Yes",Description: "VAT Number"},
    { Domain: "General Data / Bank Details", Table_Structure: "05 - BankDetails",Value: "", Mandatory: " Yes",Description: "Bank Country Key"},
    { Domain: "General Data / Bank Details", Table_Structure: "05 - BankDetails",Value: "", Mandatory: " Yes",Description: "Bank Key"},
    { Domain: "General Data / Bank Details", Table_Structure: "05 - BankDetails",Value: "", Mandatory: " Yes",Description: "Bank Account Number"},
    { Domain: "General Data / Bank Details", Table_Structure: "05 - BankDetails",Value: "", Mandatory: " Yes",Description: "Bank Control Key"},
    { Domain: "General Data / Bank Details", Table_Structure: "05 - BankDetails",Value: "", Mandatory: " Yes",Description: "Bank details ID in External System"},
    { Domain: "General Data / Bank Details", Table_Structure: "05 - BankDetails",Value: "USD", Mandatory: " Yes",Description: "Currency"},
    { Domain: "General Data / Bank Details", Table_Structure: "05 - BankDetails",Value: "", Mandatory: " Yes",Description: "IBAN"},
    { Domain: "Company Code / Accounting", Table_Structure: "11 - SupplierCompanyCode",Value: "00002", Mandatory: " Yes",Description: "Reconciliation Account in General Ledger"},
    { Domain: "ompany Code / Accounting", Table_Structure: "11 - SupplierCompanyCode",Value: "0001", Mandatory: " Yes",Description: "Terms of Payment Key"},
    { Domain: "Purchasing org", Table_Structure: "14 - SupplierPurchasingOrganization",Value: "USD", Mandatory: " Yes",Description: "Purchase Order Currency"},
    { Domain: "Purchasing org", Table_Structure: "14 - SupplierPurchasingOrganization",Value: "FOB", Mandatory: " Yes",Description: "Incoterms (Part 1)"},
    { Domain: "Purchasing org", Table_Structure: "14 - SupplierPurchasingOrganization",Value: "Freight On Board", Mandatory: " Yes",Description: "Incoterms Location 1"},
    { Domain: "Purchasing org", Table_Structure: "15 - PartnerFunction",Value: "OA", Mandatory: " Yes",Description: "Partner Function"},
    { Domain: "Purchasing org", Table_Structure: "15 - PartnerFunction",Value: "IP", Mandatory: " Yes",Description: "Partner number"},
    { Domain: "Purchasing org", Table_Structure: "15 - PartnerFunction",Value: "VN", Mandatory: " Yes",Description: "Default Partner"},
  ]
  ngOnInit() {
  }
  close(){
    this.modalController.dismiss();
  }
}
