// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-show-info-records-mass-info',
//   templateUrl: './show-info-records-mass-info.page.html',
//   styleUrls: ['./show-info-records-mass-info.page.scss'],
// })
// export class ShowInfoRecordsMassInfoPage implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }

import { Component, OnInit ,Input} from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-show-info-records-mass-info',
  templateUrl: './show-info-records-mass-info.page.html',
  styleUrls: ['./show-info-records-mass-info.page.scss'],
})
export class ShowInfoRecordsMassInfoPage implements OnInit {
  @Input() request: any;
 displayArr:any=[]
 columnData:any=[]
  constructor(public modalController: ModalController,

  ) { }

  ngOnInit() {
    console.log("request data is ",this.request)
    this.displayArr=this.request.massData;
    console.log("request data is ",this.displayArr)
    this.columnData = Object.keys(this.displayArr[0]);
  }
  close() {
    this.modalController.dismiss();
  }
}
