export var AntiCorruption = {
    value: 'corruption',
    label: 'Anti Corruption',
    type: 'standard',
    tabData: [
      {
        label: 'Are you a Government official or entity, or owned by a Government official or entity? Or do you perform services for the company that may require interaction with a government entity or official?',
        type: 'radio',
        required: true,
        model: 'ifGovOfficial',
        size: 12
      },
      {
        label: 'Do you expect to do business with sub-contractors?',
        type: 'radio',
        required: true,
        model: 'ifBusinessWithContractor',
        size: 12
      },
      {
        label: 'Are you shipping products across country border(s) ?',
        type: 'radio',
        required: true,
        model: 'ifShippingAcrossBorder',
        size: 12
      },
      {
        label: 'Do you act directly or indirectly for on behalf of any individual or entity that appears on the Specially Designed Nationals and Blocked person list, as maintained by the office of Foreign Assets Control of the US department of the Treasury or is otherwise subjected to OFAC sanctions .',
        type: 'radio',
        required: true,
        model: 'ifForeignAssestsControl',
        size: 12
      },
      {
        label: 'Do you have any relationship with are you aware of any other company relationships on anything else that may create any conflict of interest or otherwise call into question that our relationship is other than a standard independent third-party relationship?',
        type: 'radio',
        required: true,
        model: 'ifInCompanyRelationship',
        size: 12
      },
      
    ]
}