export var DataPrivacy = {
    value: 'data',
    label: 'Data Privacy',
    type: 'standard',
    tabData: [
      {
        label: "Will your company have access to the personal information of company's employees and /or customers ?Examples of personal information include name , address , phone number ,email address, date of birth , employee information typically maintained in an HRIs, customer information typically maintained in a CRM system,etc.",
        type: 'radio',
        required: true,
        model: 'ifPersonalInfoAccess'
      },
      
    ]
}