import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-idmx',
  templateUrl: './idmx.page.html',
  styleUrls: ['./idmx.page.scss'],
})
export class IdmxPage implements OnInit {

  userHost: any;
  userName: any;
  userPassword: any;
  userDatabase: any;
  databaseKeys: any = {};
  userId: any;
  filePath: any;
  database: any;
  userPort: any;
  tableName: any;
  dataName: any;
  error: null;

  model: any;
  domains: any = [
    {name: 'Material', model:'material'},
    {name: 'Customer', model:'customer'},
    {name: 'Vendor', model:'vendor'},
    {name: 'Article', model:'article'},
    {name: 'Bom', model:'bom'},
    {name: 'GL', model:'gl'},
    {name: 'Routing', model:'routing'},
    {name: 'Cost Center', model:'costCenter'},
    {name: 'Profit Center', model:'profitCenter'}
  ];

  constructor(public modalController: ModalController, public commonService: CommonService,
    private http: HttpClient, private router: Router,
    private navController: NavController) { }

  ngOnInit() {
    this.dataName = this.commonService.getdataName();
  }

  close() {
    this.modalController.dismiss();
  }

  hideShowPassword() {

  }

  createConnection() {
    let connectionString = `mysql+pymysql://${this.userName}:${encodeURIComponent(this.userPassword)}@${this.userHost}/${this.userDatabase}`
    let tableName = this.tableName;
    let filePath = this.filePath;
    this.commonService.presentLoading("Sending data to SQL DB");
    this.commonService.sendDataToIdmx(
      connectionString,
      tableName,
      filePath,
      this.model
    ).then((resp: any) => {
      this.commonService.loadingDismiss();
      this.commonService.showToast("success", resp.msg)
      this.modalController.dismiss(resp)
      // msg
      // }, (error: any) => {
      //   console.log()
      //   if (error.error['realError']['traceback'].includes("authentication failed")) {
      //     this.commonService.showToast("error", "Authentication Failed!")
      //   }
      //   this.commonService.loadingDismiss();
      //   // alert.dismiss();
      // })
    },
      error => {
        if (error.status == 200) {
          this.error = error.message;
          this.commonService.dmacshowToast("error", this.error);            
          console.log(error)
        }
        if (error.status == 400) {
          if(error.error.msg){
            this.error = error.error.msg;
            this.commonService.dmacshowToast("error", this.error);          
            console.log(error)
          }
          else{
            this.commonService.dmacshowToast("error", "Something went wrong!");   
          }
          }        
        if (error.status == 502) {
          this.error = error.message;
          this.commonService.dmacshowToast("error", this.error);         
          console.log(error)
        }
        // this.error = error.message;
        // this.commonService.showToast("error", this.error);
        // console.log(error)
      })
    return false;
  }

}
